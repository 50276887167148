import { cloneDeep as clone, merge } from 'lodash'

import api from '@api'

import sapi from '@api/service'
import mandatoryIndicators from '@assets/json/mandatoryIndicators.json'
import defaultBundles from '@styles/bundles'
import themes from '@styles/themes'

const endpoint = 'config/app'

const appConfig = {
  id: 'app',
  title: 'easyarchive',
  bundle: 'novus',
  bundles: defaultBundles,
  theme: themes,
  logo: [],
  favicon: [],
  dashboard: {
    html: [],
  },
  login: {
    logo: [],
    background: [],
  },
  flatMenu: false,
  primaryaction: '',
  mandatoryIndicator: mandatoryIndicators.default,
  defaultUpload: 'upload',
}

export default {
  namespaced: true,

  state: () => ({
    cfg: clone(appConfig),
    src: {
      logo: '',
      favicon: '',
    },
    loading: {
      logo: true,
      favicon: true,
    },
  }),

  mutations: {
    patch(state, config) {
      state.cfg = merge({}, appConfig, state.cfg, config)
    },
    set(state, config) {
      state.cfg = merge({}, appConfig, config)
    },
    src(state, { name, src }) {
      state.src[name] = src
      state.loading[name] = false
    },
    loadingImages(state, loading) {
      state.loading.logo = loading
      state.loading.favicon = loading
    },
  },

  actions: {
    async load({ state, commit, dispatch }, opts) {
      try {
        const { data = {} } = await sapi.webadapter.get(endpoint)

        if (Array.isArray(data.bundles)) {
          data.bundles = cfg.config.bundles
        }
        if (opts && opts.bundles) {
          dispatch('loadBundles', data)
        }
        if (opts && opts.themes) {
          dispatch('loadThemes', data)
        }
        if (!opts || !opts.logos) {
          commit('set', data)
          return clone(state.cfg)
        }
        if (!opts || !opts.favicons) {
          commit('set', data)
          return clone(state.cfg)
        }
        if (opts && opts.async) {
          dispatch('loadImages', data)
          commit('set', data)

          return clone(state.cfg)
        }
        return dispatch('loadImages', data).finally(() => {
          commit('set', data)
          return clone(state.cfg)
        })
      } catch (err) {
        commit('set', appConfig)
      }
      return clone(state.cfg)
    },
    loadImages({ commit, dispatch }, config) {
      commit('loadingImages', true)
      Promise.all([
        dispatch('loadImage', {
          name: 'logo',
          blob: config.logo,
          handler: api.assets.src,
        }),
        dispatch('loadImage', {
          name: 'favicon',
          blob: config.favicon,
          handler: api.assets.srcPublic,
        }),
      ]).finally(() => {
        commit('loadingImages', false)
      })
    },
    async loadImage({ commit }, { name, blob, handler }) {
      if (!Array.isArray(blob) || !blob.length) {
        commit('src', { name: name, src: '' })
        return Promise.resolve()
      }

      const res = await handler(blob[0])
      commit('src', { name: name, src: res })
      return res
    },
    loadThemes({ dispatch }, config) {
      return Promise.all([
        dispatch('loadTheme', {
          name: 'light',
          theme: config.bundles[config.bundle].light,
        }),
        dispatch('loadTheme', {
          name: 'dark',
          theme: config.bundles[config.bundle].dark,
        }),
      ])
    },
    loadTheme({ dispatch }, { name, theme }) {
      if (!theme) {
        return Promise.resolve()
      }
      return dispatch('app/theme/set', { name, theme }, { root: true })
    },
    loadBundles({ state, dispatch }, config) {
      const bundle = config.bundle ?? (config.bundle = state.cfg.bundle)
      const bundles = config.bundles ?? (config.bundles = state.cfg.bundles)

      dispatch(
        'app/theme/setDefaults',
        { bundle: bundle, bundles: bundles },
        { root: true }
      )

      dispatch('loadThemes', config)
    },
    async save({ state, commit, dispatch }, config) {
      try {
        config.id = appConfig.id
        await sapi.webadapter.post(endpoint, config)

        dispatch('loadImages', config)
        commit('set', config)
        return clone(state)
      } catch (err) {
        return config
      }
    },
  },
}
