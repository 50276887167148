import i18n from '@i18n'

import model from './model'
import sapi from './service'

function endpoint(type) {
  return `types/${type}/displays`
}

export default {
  SCOPES: Object.freeze({
    view: 'view',
    edit: 'edit',
    card: 'card',
    table: 'table',
    mobile_view: 'mobile-view',
    mobile_card: 'mobile-card',
    preview: 'preview',
  }),

  CARD_SLOTS: Object.freeze({
    CONTENT: 'content',
    HEADER: 'header',
    LEFT: 'left',
  }),

  async get(typeId, displayId) {
    const { data } = await sapi.architect.get(
      `${endpoint(typeId)}/${displayId}`
    )
    return model.display(data)
  },

  async list(typeId) {
    let { data } = await sapi.architect.get(endpoint(typeId))
    data = data || []
    data.forEach(e => {
      delete e.attributes
      delete e.view
    })
    return data.map(e => model.display(e))
  },

  async create(item) {
    const { data } = await sapi.architect.post(endpoint(item.type), item)
    sapi.success(
      i18n.t('dm.display.alert.created', {
        name: i18n.$l(item),
      })
    )
    return model.display(data)
  },

  async update(item) {
    await sapi.architect.put(`${endpoint(item.type)}/${item.id}`, item)
    sapi.success(
      i18n.t('dm.display.alert.updated', {
        name: i18n.$l(item),
      })
    )
    return model.display(item)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async delete(item) {
    await sapi.architect.delete(`${endpoint(item.type)}/${item.id}`)
    sapi.success(
      i18n.t('dm.display.alert.deleted', {
        name: i18n.$l(item),
      })
    )
  },

  async template(typeId, scope) {
    const scopeTemplateMapping = {
      [this.SCOPES.mobile_view]: this.SCOPES.view,
      [this.SCOPES.mobile_card]: this.SCOPES.card,
    }
    const requestScope = scopeTemplateMapping[scope] || scope

    const target = requestScope ? `template-${requestScope}` : 'template'
    const { data } = await sapi.architect.get(`${endpoint(typeId)}/${target}`)
    return data || {}
  },
}
