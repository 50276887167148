<template>
  <v-container
    v-scroll="onWindowScroll"
    v-resize="onResize"
    :class="isMobileAppWebView ? '' : 'fill-height mb-20 pr-8'"
    :style="{
      'padding-left': `${mobile ? '24px' : '32px'}`,
    }"
    fluid
    data-testid="page-container"
  >
    <v-row
      no-gutters
      class="fill-height flex-column flex-nowrap"
      :style="widthStyle"
    >
      <v-col
        v-show="!isMobileAppWebView"
        v-if="isMobileAppWebView || !hideHeadline"
        class="flex-grow-0 pr-3"
        data-testid="col-headline"
      >
        <v-row no-gutters :justify="justify" class="flex-nowrap">
          <v-col :style="widthStyle">
            <v-row align="center">
              <v-col
                v-if="mobile"
                order="1"
                class="text-h5 page-title"
                data-testid="txt-page-title"
              >
                <slot v-if="title" name="title">
                  <span>{{ title }}</span>
                </slot>
              </v-col>
              <v-col
                v-else
                order="1"
                class="text-h4 font-weight-semibold page-title"
                data-testid="txt-page-title"
              >
                <slot v-if="title" name="title">
                  <span>{{ title }}</span>
                </slot>
              </v-col>
              <v-col
                v-if="hasSlot('title-append')"
                :cols="mobile ? 12 : 'auto'"
                :order="mobile ? 3 : 2"
                class="flex-grow-0 py-2"
              >
                <slot name="title-append" />
              </v-col>
              <v-col
                v-if="hasSlot('actions') && readonly"
                :order="mobile ? 2 : 3"
                class="flex-grow-0 py-2"
              >
                <v-icon>mdi-lock</v-icon>
              </v-col>
            </v-row>
            <v-row v-if="hasSlot('subheader')" align="center">
              <v-col>
                <slot name="subheader" />
              </v-col>
            </v-row>
            <slot v-if="description" name="description">
              <div class="text--secondary mb-3 mt-1 caption">
                {{ description }}
              </div>
            </slot>
            <v-row v-if="hasSlot('actions')">
              <v-col :order="mobile ? 2 : 3" class="flex-grow-0 pb-6">
                <slot name="actions" />
              </v-col>
            </v-row>
            <v-row v-if="hasSlot('filter')" align="center">
              <v-col>
                <slot name="filter" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="hasSlot('toolbar')" class="flex-grow-0 pr-3 mt-3">
        <v-row no-gutters :justify="justify" class="flex-nowrap">
          <v-col :style="widthStyle">
            <v-card height="64" rounded="lg" flat>
              <slot name="toolbar" />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        id="page-scroll"
        ref="pageScroll"
        :key="renderKey"
        v-scroll.self="onScroll"
        data-testid="page-scroll"
        :class="isMobileAppWebView ? '' : contentClasses"
      >
        <v-row no-gutters :justify="justify" class="flex-nowrap">
          <v-col :style="widthStyle">
            <v-row
              no-gutters
              class="flex-nowrap"
              :style="hasSlot('sidearea') ? widthWithoutSideArea : ''"
            >
              <v-col class="page-content pa-0" cols="12">
                <div v-if="hasScroll && !loading" class="page-blur-overlay">
                  <div v-if="blurTop" class="page-blur-top" />
                  <div
                    v-if="blurBottom && !endOfPage"
                    class="page-blur-bottom"
                  />
                </div>
                <v-progress-linear
                  v-if="loading"
                  indeterminate
                  color="primary"
                  rounded
                />
                <v-col v-else class="pa-0">
                  <slot />
                </v-col>
                <v-col v-if="withContentSpacer" class="spacer-col" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-fab-transition v-if="scrollToTop">
      <v-btn
        v-show="showFab"
        color="primary"
        class="mr-1"
        fab
        dark
        bottom
        right
        fixed
        data-testid="btn-scroll-to-top"
        @click="scrollTo(0)"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-card
      v-if="hasSlot('sidearea')"
      elevation="0"
      class="page-sidearea pr-0 pb-0 pl-0"
      :style="`padding-top: ${headerHeight}px`"
      :width="sideAreaWidth"
    >
      <div class="page-sidearea-scroll">
        <slot name="sidearea" />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'BPage',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    justify: {
      type: String,
      default: 'start',
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    thin: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    scrollToTop: {
      type: Boolean,
      default: false,
    },
    hideHeadline: {
      type: Boolean,
      default: false,
    },
    withContentSpacer: {
      type: Boolean,
      default: true,
    },
    sideAreaWidth: {
      type: Number,
      default: 480,
    },
  },
  data() {
    return {
      renderKey: 0,
      blurOffset: 12,
      fabOffset: 200,
      toolbarHeight: 0,

      mounted: false,
      blurTop: false,
      blurBottom: true,
      endOfPage: false,
      showFab: false,
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    hasScroll() {
      return !this.noScroll && !this.mobile
    },
    width() {
      if (this.narrow) return '736px'
      if (this.thin) return '900px'
      if (this.wide) return '1500px'
      if (this.fluid) return '100vw'
      return '100%'
    },
    widthStyle() {
      return {
        'max-width': this.width,
        width: '100%',
      }
    },
    widthWithoutSideArea() {
      return `width: calc(100% - ${this.sideAreaWidth}px)`
    },
    contentClasses() {
      return {
        'mt-4': !this.hideHeadline && !this.description,
        'pr-1': this.hasScroll,
        'pr-3': !this.hasScroll,
        'page-scroll': !this.noScroll && !this.loading && !this.mobile,
      }
    },
    headerHeight(){
      return 56 + this.warningHeight
    },
    ...mapGetters('app/view', ['height', 'warningHeight']),
    ...mapState({
      hideAllMenus: state => state.app.context.hideAllMenus,
      readonly: state => state.app.detail.readonly,
    }),
    isMobileAppWebView() {
      return this.mobile && this.hideAllMenus
    },
  },
  watch: {
    loading(val) {
      if (val) {
        this.renderKey++
        this.showFab = false
        this.blurTop = false
      }
    },
  },
  async mounted() {
    await this.$nextTick()
    this.mounted = true
    this.onResize()
    await this.$nextTick()
    if (this.hasSlot('toolbar')) {
      this.toolbarHeight = 96
    }
  },
  updated() {
    this.$nextTick(() => {
      this.onResize()
    })
  },
  methods: {
    hasSlot(name) {
      return !!this.$scopedSlots[name]
    },
    scrollTo(target) {
      const opts = { duration: 300 }
      if (!this.mobile) opts.container = '#page-scroll'
      this.$vuetify.goTo(target, opts)
    },
    onScroll(e) {
      const t = e.target
      const b = t.scrollHeight - this.blurOffset - t.scrollTop

      this.showFab = t.scrollTop > this.fabOffset
      this.blurTop = t.scrollTop > this.blurOffset
      this.blurBottom = b >= t.clientHeight
      this.$emit('scroll', e)
    },
    onResize() {
      if (this.mounted) {
        const e = this.$el?.querySelector?.('#page-scroll')
        this.endOfPage = e && e.scrollHeight <= e.clientHeight
      }
    },
    onWindowScroll() {
      this.showFab = window.pageYOffset > this.fabOffset
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  min-height: 64px;
}
.page-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: hidden;
}
.page-content,
.page-scroll:hover,
.page-scroll:focus {
  visibility: visible;
  position: relative;
}
.page-blur-overlay {
  visibility: visible;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.page-blur-top {
  position: -webkit-sticky;
  position: sticky;
  pointer-events: none;
  top: 0;
  height: 10px;
  z-index: 2;
  box-shadow: inset 0 6px 6px -3px var(--v-background-base);
}
.page-blur-bottom {
  // sticky not working for some reason
  position: fixed;
  pointer-events: none;
  bottom: 0;
  width: 100%;
  height: 10px;
  z-index: 2;
  margin-bottom: 0px;
  box-shadow: inset 0 -6px 6px -3px var(--v-background-base);
}
.page-sidearea {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
}

.spacer-col {
  padding-bottom: 120px;
}
</style>
