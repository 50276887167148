import i18nInstance from '@i18n'

import i18n from './i18n'

/**
 * Define feature constants
 */
const VERSION = 'v0.0.1'
const FEATURE = 'lexware'
const NAME = 'Lexware'

/**
 * Define feature info with details of registered components
 */
const INFO = {
  name: NAME,
  feature: FEATURE,
  i18n: initI18n(),
  version: VERSION,
}

export default INFO

/**
 *  Register and initialize localization context
 */
function initI18n() {
  Object.keys(i18n).forEach(key => {
    i18nInstance.add(key, { [FEATURE]: i18n[key] })
  })

  return true
}
