import sapi from './service'

const endpoint = 'extensions'
const endpointRegistration = `${endpoint}/registrations`
const endpointExtension = `${endpoint}/extension`

export default {
  STATES: Object.freeze({
    STABLE: 'Stable',
    BETA: 'Beta',
    EXPERIMENTAL: 'Experimental',
  }),

  async loadExtensions(state) {
    let params = {}
    if (state) params = { state: state }
    let { data } = await sapi.extender.get(`${endpoint}/marketplace`, {
      params: params,
    })
    data = data || []
    return data
  },

  async loadRegistrations() {
    let { data } = await sapi.extender.get(endpointRegistration)
    data = data || []
    return data
  },

  async getRegistration(id) {
    const { data } = await sapi.extender.get(`${endpointRegistration}/${id}`)
    return data
  },

  async getStatus(id) {
    const { data } = await sapi.extender.get(
      `${endpointRegistration}/${id}/status`
    )
    return data.status
  },

  async getStatusByName(name) {
    try {
      const { data } = await sapi.extender.get(
        `${endpointExtension}/${name}/status`,
        { omitError: true }
      )
      return data.status
    } catch (err) {
      if (err.response?.data?.message) {
        console.error(err.response.data.message)
      } else {
        console.error(err)
      }
      return {}
    }
  },

  async getConfiguration(id) {
    const { data } = await sapi.extender.get(
      `${endpointRegistration}/${id}/configuration`
    )
    return data
  },

  async create(id, silent) {
    const { data } = await sapi.extender.post(
      endpointRegistration,
      {
        marketplaceId: id,
      },
      {
        omitError: silent,
      }
    )
    return data
  },

  async updateConfiguration(id, item, user) {
    const { data } = await sapi.extender.put(
      `${endpointRegistration}/${id}/configuration`,
      {
        properties: item,
        termsOfUseAcceptedBy: user,
      }
    )
    return data
  },

  async updateStatus(id, activate, silent) {
    const body = { status: activate ? 'activated' : 'deactivated' }
    const { data } = await sapi.extender.put(
      `${endpointRegistration}/${id}/status`,
      body,
      { omitError: silent }
    )
    return data
  },

  async destroy(id) {
    await sapi.extender.delete(`${endpointRegistration}/${id}`)
  },
}
