import sapi from './service'

const endpoint = 'billing'

export default {
  async period(period, name = '') {
    const { data } = await sapi.webadapter.get(`${endpoint}/period/${period}`)
    if (!name) {
      return data?.aggregations
    } else {
      return data?.aggregations?.find(item => item.name === name)
    }
  },
  async exceeded() {
    const { data } = await sapi.webadapter.get(`${endpoint}/exceeded`)
    return data
  },
}
