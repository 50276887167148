import sapi from './service'

const endpoint = 'chatassistant'

export default {
  async conversationNlQuery(prompt) {
    const { data } = await sapi.webadapter.post(`${endpoint}`, {
      prompt,
    })
    return data
  },
}
