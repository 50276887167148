import { cloneDeep as clone } from 'lodash'

import api from '@api'

import sapi from '@api/service'
import themes from '@styles/themes'

const endpoint = 'public/config/app'

const publicConfig = {
  theme: themes,
  login: {
    text: '',
    logo: [],
    background: [],
  },
}

export default {
  namespaced: true,

  state: () => ({
    cfg: clone(publicConfig),
    src: {
      logo: '',
      background: '',
    },
    loading: {
      logo: true,
      background: true,
    },
  }),

  mutations: {
    set(state, config) {
      Object.assign(state.cfg, publicConfig, config)
    },
    src(state, { name, src }) {
      state.src[name] = src
      state.loading[name] = false
    },
  },

  actions: {
    async load({ state, commit, dispatch }) {
      try {
        let { data } = await sapi.webadapter.get(endpoint)
        data = data || {}
        commit('set', data)
        await Promise.all([
          dispatch('loadImages', state.cfg),
          dispatch('dm/config/loadBundles', data, { root: true }),
          dispatch('app/theme/setDark', false, { root: true }),
        ])
      } catch (err) {
        commit('set', publicConfig)
      }
      return clone(state.cfg)
    },
    loadImages({ dispatch }, config) {
      return Promise.all([
        dispatch('loadImage', {
          name: 'logo',
          blob: config.login.logo,
        }),
        dispatch('loadImage', {
          name: 'background',
          blob: config.login.background,
        }),
      ])
    },
    async loadImage({ commit }, { name, blob }) {
      if (!Array.isArray(blob) || !blob.length) {
        commit('src', { name: name, src: '' })
        return Promise.resolve()
      }
      const res = await api.assets.srcPublic(blob[0])
      commit('src', { name: name, src: res })
      return res
    },
  },
}
