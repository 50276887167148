import api from '@api'

import sapi from '@api/service'
export default {
  namespaced: true,

  state: () => ({
    billingData: [],
    billingQuery: null,
  }),

  mutations: {
    setBillingData(state, data) {
      state.billingData = data
    },
    setBillingQuery(state, query) {
      state.billingQuery = query
    },
  },

  actions: {
    /**
     * Fetch billing data for a specific period and store it in the state.
     * @param {Object} context - Vuex context object.
     * @param {String} period - The billing period 0-11 to fetch (e.g., 0 = current month).
     */
    async fetchBillingData({ commit }, period = 0) {
      try {
        const data = await api.billing.period(period)
        commit('setBillingData', data)
        commit('setBillingQuery', period)
        return data
      } catch (err) {
        commit('setBillingData', [])
        sapi.error(err)
      }
    },
    async initializeEnabledExtensions({ dispatch }, data) {
      const extensions = getExtensions(data)
      const extensionNames = Object.keys(extensions)

      const enabledExtensions = {}
      const promises = extensionNames.map(async name => {
        const isActive = await dispatch('dm/extensions/isActiveByName', name, {
          root: true,
        })
        extensions[name].forEach(
          extension => (enabledExtensions[extension] = isActive)
        )
      })
      await Promise.all(promises)
      return enabledExtensions
    },
    async loadLimits({ commit }) {
      const data = await api.billing.exceeded()
      if (data.length === 1) {
        showAppWarning(commit, data[0], data[0].consumptionPercent >= 100)
      } else if (data.length > 1) {
        const exceededItems = data.filter(
          item => item.consumptionPercent >= 100
        )
        if (exceededItems.length === 1) {
          showAppWarning(commit, exceededItems[0])
        } else if (exceededItems.length > 1) {
          showAppWarning(commit, {}, true, true)
        } else {
          showAppWarning(commit, {}, false, true)
        }
      }
    },
  },

  getters: {
    /**
     * Filter billing data by the given name.
     * @param {Object} state - Vuex state.
     * @returns {Function} - A function that filters billing data by name.
     */
    getBillingDataByName:
      state =>
      (name = 'generic-extraction') => {
        return state.billingData.filter(el => el.name === name)
      },
  },
}

/*
 * get extensions from billing data
 * @returns {Object} extensions
 * example: {
 *	'generic-extraction' => ['generic-extraction']
 *	'invoice' => ['einvoice_extract', 'einvoice_validate']
 * }
 */
function getExtensions(data) {
  const extensions = {}
  data.forEach(item => {
    let name = item.name
    if (name.startsWith('einvoice_')) {
      name = 'invoice'
    } else if (name.startsWith('cmis_')) {
      name = 'cmis'
    }
    if (!extensions[name]) {
      extensions[name] = [item.name]
    } else {
      extensions[name].push(item.name)
    }
  })
  return extensions
}

function showAppWarning(commit, item = {}, exceeded = true, several = false) {
  const values = { name: item.label, percent: item.consumptionPercent }
  commit(
    `app/view/${exceeded ? 'error' : 'warning'}`,
    {
      key: `billing.exceeded.${exceeded ? 'complete' : 'almost'}.${
        several ? 'several' : 'one'
      }`,
      values,
    },
    { root: true }
  )
}
