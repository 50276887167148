import sapi from './service'

const endpoint = '/config/assets'
const publicEndpoint = 'public/config/assets'

export default {
  async src(file) {
    const data = await sapi.webadapter.blob.src(endpoint, file, {
      omitError: true,
    })
    return data
  },

  async text(file) {
    const data = await sapi.webadapter.blob.text(endpoint, file)
    return data
  },

  async srcPublic(file) {
    const data = await sapi.webadapter.blob.src(publicEndpoint, file, {
      omitError: true,
    })
    return data
  },

  async download(file) {
    const data = await sapi.webadapter.blob.download(endpoint, file)
    return data
  },

  async downloadPublic(file) {
    const data = await sapi.webadapter.blob.download(publicEndpoint, file)
    return data
  },

  async upload(file) {
    const data = await sapi.webadapter.blob.upload(endpoint, file)
    return data
  },

  async uploadPublic(file) {
    const data = await sapi.webadapter.blob.upload(publicEndpoint, file)
    return data
  },
}
