import { UserManager } from 'oidc-client-ts'

import store from '@state/store'

class ZitadelAuthService {
  constructor() {
    this.ready = false
    this.authenticated = false
    this.customer = null
  }

  async load(customer, token, refreshToken, clientId) {
    if (token && clientId) {
      this.token = token
      this.tokenParsed = parseToken(token)
      this.refreshToken = refreshToken
      return
    }

    clientId = store.state.app.login.zids?.clientId
    const issuer = store.state.app.login.zissuer
    const idpId = store.state.app.login.idp
    const orgId = store.state.app.login.zids?.orgId
    const projectId = store.state.app.login.zids?.projectId
    const redirectUri = `${window.location.origin}/login-callback`

    const scope =
      'openid profile email ' +
      `urn:zitadel:iam:org:project:id:${projectId}:aud` +
      (orgId ? ` urn:zitadel:iam:org:id:${orgId}` : '') +
      (idpId ? ` urn:zitadel:iam:org:idp:id:${idpId}` : '')

    const oidcConfig = {
      response_type: 'code',
      scope: scope,
      authority: issuer,
      client_id: clientId,
      redirect_uri: redirectUri,
      loadUserInfo: false,
      automaticSilentRenew: true,
    }

    this.UserManager = new UserManager(oidcConfig)
    this.customer = customer
    this.authenticated = false
    this.ready = true
  }

  async login(options) {
    if (options.redirectUri) {
      store.commit('app/login/redirectTo', options.redirectUri)
    }
    return this.UserManager.signinRedirect({
      login_hint: options.loginHint,
    })
  }

  async loginCallback() {
    const user = await this.UserManager.signinRedirectCallback()
    this.token = user.access_token
    this.refreshToken = user.refresh_token
    this.tokenParsed = this.parseToken(this.token)
    await store.commit('app/context/token')
    this.authenticated = true
  }

  async logout(options) {
    return this.UserManager.signoutRedirect({
      post_logout_redirect_uri: options.redirectUri,
    })
  }

  parseToken(token) {
    let p1 = token.indexOf('.')
    let p2 = token.lastIndexOf('.')
    let payload = token.substring(p1 + 1, p2)
    return JSON.parse(atob(payload))
  }

  clearToken() {
    this.token = ''
    this.tokenParsed = ''
    this.authenticated = false
  }
}

const zauth = new ZitadelAuthService()

export default zauth
