export function getEnvVar(name) {
  try {
    return window.config[name] || process.env[name]
  } catch {
    return ''
  }
}

export const productUrls = Object.freeze({
  archivesaas: [
    'archive.easy.de',
    'archive.easy-software.com',
    'one-dm-archive-dev.easy02.proactcloud.de',
    'gemini-archive-dev.easy02.proactcloud.de',
    'laconia-archive-dev.easy02.proactcloud.de',
  ],
  lexwarearchivierung: [
    'archiv.lexware.de',
    'one-dm-lexware-archive-dev.easy02.proactcloud.de',
    'gemini-lexware-archive-dev.easy02.proactcloud.de',
    'laconia-lexware-archive-dev.easy02.proactcloud.de',
    'one-dm-lexware-archive-qa.easy02.proactcloud.de',
  ],
})

export const productUrlExceptions = Object.freeze({
  archivesaas: ['verbraucherzentrale-hessen.archive.easy.de'],
})

export const productRouteExceptions = Object.freeze({
  archivesaas: ['archivelogin-tenant'],
  lexwarearchivierung: ['lexwarelogin-tenant'],
})
